export default `제1장 서비스 이용약관
제1조 (목적)
이 약관(이하 “약관”)은 주식회사 해시스냅(이하 “회사”)이 제공하는 “해시스냅”서비스(이하 “서비스”)의 이용과 관련한 서비스 이용 조건 및 기타 필요한 사항을 규정함을
목적으로 합니다.
제2조 (정의)
1) "서비스"라 함은 "이용사"가 요청한 소셜네트워킹서비스(social networking service, 이하 “SNS”) 계정 또는 해시태그(#)를 등록하여 그 해당 포스트를 수집한 뒤 특정 단말기(PC, 모바일, TV, 스크린, DID 등 각종 송출 기기)를 통해 송출하고, 인화하는 솔루션을 통칭합니다.
2) "이용사"라 함은 "약관"에 따라 “회사”와 “서비스”의 이용계약을 체결하고 “회사”에서 제공하는 "서비스"를 사용하는 자를 의미합니다.
3) “캠페인”이라 함은 “이용사”가 SNS, 검색기간, 해시태그(#)를 특정하여 “회사”의 “서비스”에 등록한 뒤 “이용사”의 고객으로 하여금 특정 SNS 상에 특정 해시태그(#)를 기반으로 콘텐츠를 공유, 게시, 또는 업로드 하도록 함으로써 “이용사”의 브랜드 내지 상품을 홍보하는 것을 말합니다.
제3조 (약관의 게시와 개정)
1) "회사"는 "약관"의 내용을 "이용사"가 쉽게 알 수 있도록 "서비스" 초기화면 또는 연결화면을 통해 게시합니다.
2) "회사"는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 "약관"을 개정할 수 있습니다.
3) "회사"는 "약관"을 개정할 경우 개정내용과 적용일자를 명시하여 "서비스"에서 적용일자 7일전부터 적용일자 전일까지 공지합니다. 다만, "이용사"에게 불리하게 개정되는 경우 적용일자 30일 전부터 공지합니다.
4) "회사"가 전 항에 따라 공지하였음에도 "이용사"가 적용일자 전일까지 명시적으로 거부의 의사표시를 하지 않은 경우 "이용사"가 개정 약관에 동의한 것으로 봅니다.
5) "이용사"는 개정 약관에 동의하지 않는 경우 적용일자 전일까지 "회사"에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.
제4조 (운영정책)
1) "회사"는 공정한 "서비스"의 유지 및 "이용사"의 보호 등을 위해 별도의 운영정책을 운영할 수 있으며, "이용사"는 "회사"가 정한 운영정책을
반드시 확인하고 준수하여야 합니다.
2) "약관"에서 정하지 않은 사항이나 해석에 대하여는 운영정책, 이용안내, 관련 법령에 따릅니다.
제5조 (이용계약)
1) “이용사”가입은 “서비스”를 이용하고자 하는 자(이하 “이용사”)가 “약관”의 내용에 동의와 “이용사”의 이용 신청에 대한 회사의 이용 승낙으로 성립됩니다.
2) "회사"는 "이용사"의 신청에 대하여 아래 각 호의 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 이용계약을 해지할 수 있습니다.
① 타인(또는 타법인)의 명의를 도용한 경우
② 내용을 허위로 기재하거나 “회사”가 제시하는 사항을 기재하지 않은 경우
③ “이용사”가 “약관”에 의하여 이전에 “이용사” 자격을 상실한 적이 있는 경우
④ “이용사”가 “약관 위반 등이 사유로 “서비스” 이용제한 중에 이용계약 해지 후 재가입을 하는 경우
⑤ "이용사"의 귀책사유로 인하여 승낙이 불가능하거나 기타 "약관"에서 규정한 제반 사항을 위반하여 신청을 하는 경우
3) 제1항에 따른 신청에 대하여 "회사"는 전문기관을 통한 실명확인 및 본인인증을 "이용사"에게 요청할 수 있습니다.
4) "이용사" 가입의 성립 시기는 "회사"가 가입 완료를 신청절차상에서 표시하거나, 운영방식에 따른 통지가 "이용사"에게 도달한 시점으로 합니다.
제2장 개인정보 처리 방침
제6조 (개인정보의 보호)
"회사"는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "개인정보보호법" 등 관계 법령이 정하는 바에 따라 "이용사"의 개인정보를 보호하기 위해 노력합니다.
개인정보의 보호 및 사용에 대해서는 관련법령 및 "회사"의 개인정보처리방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.
제7조 (회사의 의무)
1) "회사"는 관련 법령과 "약관"을 준수하며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
2) "회사"는 "이용사"가 안전하게 이용하도록 개인정보(신용정보 포함) 보호를 위하여 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
3) "회사"는 "서비스" 이용과 관련하여 "이용사"로부터 제기된 의견이나 불만이 정당하다고 인정될 경우 이를 처리하여야 하며, "서비스" 내 게시판, 전자우편 등을 통하여 "이용사"에게 처리과정 및 결과를 전달한다.
4) "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체, 고장, 장애, 통신두절 또는 운영상 합리적인 사유가 있는 경우 "서비스" 제공을 일시적으로 중단할 수 있으며, 본 항에 따른 "서비스" 제공 중단으로 발생한 "이용사"의 손해에 대하여 고의 또는 과실이 없는 한 책임을 지지 않습니다.
5) 전항의 경우 "회사"는 제1５조에 따른 방법으로 "이용사"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
제8조 (의 의무)
1) "이용사"는 아래의 행위를 하여서는 안됩니다.
① "이용사" 가입을 신청하거나 "이용사" 정보 변경을 신청할 때 허위 또는 타인의 정보를 입력하는 행위
② "회사" 또는 제3자의 저작권 등 지적재산권을 침해하는 행위
③ "회사" 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
④ "회사" 또는 제3자를 가장 또는 사칭하여 글을 게시하거나 메일을 발송하는 행위
⑤ "회사"의 "서비스"에 게시된 정보를 변경하거나 "서비스"를 이용하여 얻은 정보를 "회사"의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
⑥ 계정을 매매, 양도, 명의변경, 재 판매하거나 질권의 목적으로 사용하는 행위
⑦ "서비스" 이용 권한을 타인에게 공유, 제공, 양도, 중개, 재 판매하는 행위
⑧ 기타 "서비스"의 운영정책에 위배되는 모든 행위
제9조 (서비스의 변경)
1) ”회사"는 안정적인 "서비스" 제공을 위하여 "서비스"의 내용, 운영상 또는 기술상 사항 등을 변경할 수 있습니다.
2) "회사"는 "서비스"를 변경할 경우 변경내용과 적용일자를 명시하여 "서비스"에서 사전에 공지합니다. 단, "이용사"의 권리나 의무 및 "서비스" 이용과 관련되는 실질적인 사항을 변경할 경우 적용일자 7일전부터 공지하며, "이용사"에게 불리한 변경의 경우 적용일자 30일전부터 공지합니다.
3) "이용사"는 "서비스" 변경에 동의하지 않을 경우 "회사"에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.
제10조 (캠페인의 등록)
1) “이용사”가 검색하고자 하는 "캠페인"은 해시태그(#), 검색기간, SNS를 특정하여 “회사”에 등록 신청하여야 합니다.
2) “이용사”는 “캠페인” 등록할 해시태그(#)를 특정할 때에 “캠페인”과 관련 없는 콘텐츠가 검색되는 일이 발생하지 않도록 주의하여야 합니다.
3) "회사"는 "이용사"의 "캠페인" 등록 신청에 대하여 "약관" 및 운영정책에 따라 심사하며 "이용사"가 등록 신청한 "캠페인"이 "약관" 및 운영정책에 부합하지 않는 경우 "회사"는 해당 "캠페인"의 등록을 거절하거나 취소할 수 있습니다.
제11조 (데이터의 수집 및 이용)
1) "회사"는 "서비스"의 원활한 제공을 위하여 "캠페인"과 디바이스의 정보를 수집할 수 있습니다. "회사"는 수집하는 데이터의 주요 항목에 대해 운영정책을 통하여 안내할 수 있습니다.
2) "회사"는 전항에 따라 수집한 정보를 "회사"가 제공하는 “서비스” 등을 위하여 이용할 수 있으며, 관련 법령에 따라 제3자에게 제공할 수 있습니다.
제12조 (이용제한 등)
1) "회사"는 "이용사"가 관련법령, "약관" 또는 운영정책상 의무를 위반하거나 기타 "서비스"의 정상적인 운영을 방해하는 경우 검색 중지, 이용계약의 직권 해지 등 단계적으로 이용을 제한할 수 있습니다.
2) "회사"는 전항에도 불구하고 "이용사"가 주민등록법, 저작권법 및 컴퓨터프로그램보호법, 정보통신망이용촉진 및 정보보호 등에 관한 법 등을 위반한 경우 또는 "서비스" 운영에 악영향을 미치는 경우에는 즉시 영구 이용제한 조치를 하거나 이용계약을 직권 해지할 수 있습니다.
3) "회사"가 "이용사"에게 이용제한을 하는 경우 "회사"는 이 약관 제15에 따라 "이용사"에게 통지합니다.
4) “이용사”는 "회사"의 이용제한에 대해"회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 해당 조치를 철회할 수 있습니다.
제13조 (이용계약 해지)
1) “이용사”는 언제든지 고객센터로 요청하거나 직접 "서비스"에 접속하여 이용계약의 해지를 신청할 수 있으며, "회사"는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
2) “이용사”가 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 “회사”가 “이용사” 정보를 보유하는 경우를 제외하고는 해지 즉시 “이용사”의 모든 데이터는 소멸됩니다.
3) “이용사”는 이용계약의 해지일로부터 6개월 이후 재가입 신청을 할 수 있으며, 해지일로부터 6개월 이내에는 해지한 "이용사" 정보로 다시 가입하는 것이 제한될 수 있습니다. 다만, 타인의 명의도용으로 인하여 이용계약이 해지되는 경우 그러하지 않습니다.
4) "약관"에 따라 이용계약이 직권 해지된 "이용사" 또는 이용제한 중 해지한 "이용사"는 재가입이 제한될 수 있습니다.
5) "회사"는 "이용사"의 이용계약이 해지되는 경우 "약관"에 따른 이용계약을 직권으로 즉시 해지합니다.
제14조 (이용계약 해지 시 처리)
1) "이용사" 또는 "회사"가 이용계약 해지 시, 서비스 종류 및 이용 형태에 따라 각기 다른 의무와 권한을 가지므로 각 계약서 내 명기된 내용에 따라 처리합니다.
2) "약관" 제12조 제2항에 따라 이용계약이 해지된 경우 "회사"는 이미 결제된 이용금액 전액을 "이용사"에게 환급하지 않습니다.
제15조 (이용사에 대한 통지)
1) "회사"가 "이용사"에 대하여 통지를 하는 경우 "약관"에 별도의 규정이 없는 한 “이용사”가 제공한 전자우편주소, (휴대)전화번호 등의 수단으로 할 수 있습니다.
2) "회사"는 "이용사" 전체에 대하여 통지를 하는 경우 7일 이상 "서비스" 내 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, "이용사"의 "서비스" 이용에 중대한 영향을 주는 사항에 대해서는 전항의 통지 수단 중 2개 이상의 방법으로 통지합니다.
3) “이용사”는 "회사"에 실제로 연락이 가능한 전자우편, (휴대)전화번호 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며, "회사"의 통지를 확인하여야 합니다.
4) “이용사”는 전항의 의무를 소홀해 발생한 불이익에 대해서는 보호받지 못합니다.
제16조 (게시물의 저작권)
1) "이용사"는 “캠페인” 개시 전 “켐페인”의 참여자에게 “캠페인”에 등록된 SNS, 해시태그(#), 검색기간, ”서비스”의 내용을 고지하고, “캠페인”의 참여자가 해당 SNS상에 특정 해시태그(#)를 기반으로 콘텐츠를 공유, 게시, 또는 업로드 할 때 “캠페인”의 참여자는 “회사” 및 “이용사”에게 해당 콘텐츠를 “서비스”의 운영에 필요한 범위 내에서 수정, 복제, 편집, 2차 저작물 작성 등을 할 수 있는 무상의 이용권한을 부여한다는 사실을 “캠페인”의 참여자에게 캠페인 현장안내와 홈페이지 공지 등 온라인과 오프라인상으로 반드시 고지하여야 합니다.
2) “회사”는 “캠페인”의 참여자가 위 제1항에서 고지에 따라 “캠페인”에 등록된 특정 검색기간 동안 SNS상에 특정 해시태그(#)를 기반으로 공유, 게시, 또는 업로드 한 콘텐츠 자체 또는 파일에 한하여 “서비스”를 제공합니다.
3) “이용사”는 “캠페인”을 시행할 때와 “서비스”를 이용할 때에 누구든지 타인의 저작권 내지 초상권을 침해하는 행위를 하지 않도록 고지하고, 타인의 저작권 내지 초상권이 침해되는 일이 발생하지 않도록 방지할 주의의무가 있습니다.
4) “이용사”가 위 제1항 내지 제3항을 위반한 경우, “이용사”는 “회사” 및 제3자에 대한 모든 민형사상 책임을 부담합니다.
제3장 책임의 한계와 법적 고지
제17조 (권리의 귀속)
1) "회사"가 "이용사"에게 제공하는 "서비스"에 대한 저작권 내지 지적재산권은 "회사"에 귀속됩니다.
2) “이용사”가 “서비스”를 이용하거나 “서비스”에 콘텐츠를 게시할 때에 “이용사”의 고객에 대한 초상권 내지 저작권 침해가 발생될 우려가 있는 경우 해당 고객의 사전 승낙 없이 할 수 없습니다.
3) “이용사”는 “서비스”를 이용하여 “캠페인”과 무관한 콘텐츠를 수집하여서는 아니 되며, 특정 해시태그(#)에도 불구하고 “캠페인”과 무관한 콘텐츠가 수집된 경우 “이용사”는 절대 그 콘텐츠를 사용하거나 그 콘텐츠의 저작권 내지 초상권을 침해하여서는 안됩니다.
4) “이용사”가 “서비스”에 콘텐츠를 게시할 때, “이용사”는 해당 콘텐츠를 “서비스”의 운영, 홍보 및 개선, 새로운 서비스 개발 등 필요한 범위 내에서 수정, 복제, 편집, 2차 저작물 작성 등을 할 수 있는 무상의 이용권한을 “회사”에게 부여하게 됩니다.
5) “회사”는 제4항 이외의 목적으로 “이용사”의 콘텐츠를 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 “이용사”의 동의를 얻어야 합니다.
6) “이용사”는 해시스냅을 이용하여 유사한 서비스를 제작, 운영할 수 없습니다.
7) “이용사”가 위 제1항 내지 제3항을 위반한 경우, “이용사”는 “회사” 및 제3자에 대한 모든 민형사상 책임을 부담합니다.
제18조 (손해배상 등)
1) "회사"는 "서비스" 이용과 관련하여 "회사"의 고의 또는 과실로 인해 "이용사"에게 손해가 발생한 경우 관련 법령이 규정하는 범위 내에서 "이용사"에게 그 손해를 배상합니다.
2) “이용사”가 "약관" 또는 관련 법령을 위반하여 "회사"에 손해가 발생한 경우 관련 법령이 규정하는 범위 내에서 "회사"에 그 손해를 배상합니다.
제19조 (책임제한)
1) "회사"는 천재지변, 디도스(DDOS)공격, IDC장애, SNS의 정책 변경 또는 장애, 기간통신사업자의 회선 장애, 미디어의 장애 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임을 지지 않습니다.
2) "회사"는 "이용사"의 귀책사유로 인한 "서비스" 이용 장애에 대하여는 책임을 지지 않습니다. “이용사”의 귀책사유가 될 수 있는 기기 관련 주의사항은 *별첨 해시스냅 프린터 이용 주의사항을 따릅니다.
3) "회사"는 "서비스"를 통해 얻은 정보, 자료의 신뢰도, 정확성 등의 내용에 관해서는 책임을 부담하지 않습니다.
4) “이용사”가 등록한 "캠페인"의 내용, 영업 등에 대한 모든 책임은 "이용사"에 있으며 "회사"는 이에 대하여 책임을 지지 않습니다.
제20조 (준거법 및 재판관할)
1) "회사"와 "이용사" 간 발생한 분쟁에 대하여는 대한민국법을 준거법으로 합니다.
2) "회사"와 "이용사" 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.
시행일자 : 본 약관은 2021년 2월 25일부터 시행됩니다.`