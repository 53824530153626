import React, { useEffect, useRef } from "react"
import styled from 'styled-components'
import terms from '../constants/terms'

const number = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
const title = number.map(item => `제${item}장`);
const subTitle = number.map(item => `제${item}조`);

const termsMapping = (string) => string.split('\n').filter(line => line !== '').map(item => {
  if(title.indexOf(item.split(' ')[0]) !== -1){
    return `<h3 class="mt-32 mb-10 font-noto text-18 md:text-24 font-medium">${item}</h3>`
  }else if(subTitle.indexOf(item.split(' ')[0]) !== -1){
    return `<h4 class="mt-20 my-10 font-noto text-15 md:text-18 font-normal text-red">${item}</h4>`
  }
  return `<p class="font-noto text-13 md:text-15 font-light leading-26 md:leading-28">${item}</p>`;
}).join('');

const Content = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 80px;
  @media (min-width: 720px){
    width: 680px;
  }
  @media (min-width: 1024px){
    width: 720px;
    padding: 140px 30px 120px;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    opacity: 0.95;
    margin: 40px 0 15px;
  }
  h4 {
    font-size: 17px;
    line-height: 28px;
    font-weight: normal;
    opacity: 0.95;
  }
  p {
    font-size: 15px;
    line-height: 26px;
    opacity: 0.8;
    margin-top: 8px;
    margin-bottom: 0;
  }
`;


const Terms = () => {
  const content = useRef();
  useEffect(() => {
    content.current.innerHTML = termsMapping(terms)
  }, []);

  return <Content>
    <h2 style={{marginBottom: 40}}>서비스 이용약관</h2>
    <div ref={content}></div>
  </Content>
}

export default Terms;